import React from 'react';
import logo from '../assets/logo.png';
import { scrollToSection } from '../utils/scrollToSection';

const Header: React.FC = () => {
  return (
    <header className="bg-gray-800 p-4 flex justify-between items-center fixed top-0 left-0 w-full z-50">
      <div className="flex items-center">
        <div className="bg-white rounded-full p-1.5 flex items-center justify-center">
          <img src={logo} alt="Logo" className="w-10 h-10" />
        </div>
        <h1 className="text-white ml-4">Prova Simples</h1>
      </div>

      <nav className="ml-auto mr-8 hidden md:block">
        <ul className="flex space-x-4 text-white">
          <li>
            <button
              onClick={() => scrollToSection('demo')}
              className="hover:underline text-white focus:outline-none"
            >
              Como Funciona
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('subscription')}
              className="hover:underline text-white focus:outline-none"
            >
              Inscreva-se
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
