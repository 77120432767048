import React from 'react';
import examImage from '../assets/exam-multiple-choice.jpg';
import { scrollToSection } from '../utils/scrollToSection';

const Hero: React.FC = () => {
  return (
    <section 
      id="hero" 
      className="relative flex flex-col md:flex-row items-center justify-center md:justify-between h-[calc(100vh-4rem)] w-full bg-cover bg-center overflow-hidden" 
      style={{ 
        backgroundImage: `url(${examImage})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative w-full md:w-1/2 p-6 md:p-12 flex justify-center md:justify-center items-center text-white z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-center md:text-left">Prova Simples</h1>
      </div>

      <div className="relative w-full md:w-1/2 p-6 md:p-12 flex flex-col justify-center items-center md:items-start text-white z-10">
        <p className="text-lg mb-4 text-center md:text-left">
          Transforme sua forma de estudar e conquiste os melhores resultados em vestibulares e concursos. Com a ajuda de nossa poderosa ferramenta de Inteligência Artificial, você otimiza seu desempenho e garante a vantagem necessária para alcançar o sucesso.
        </p>
        <a
          onClick={() => scrollToSection('demo')}
          className="bg-blue-600 text-white px-6 py-3 w-fit rounded-full text-lg font-semibold hover:bg-blue-500 transition duration-300 mt-4 shadow-lg cursor-pointer"
        >
          Como Funciona
        </a>
      </div>
    </section>
  );
};

export default Hero;
