import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './sections/Hero2';
import Contact from './sections/Contact';
import Pricing from './sections/Princing';
import SectionDivider from './components/SectionDivider';
import Demo from './sections/Demo';
import Subscription from './sections/Subscription';


const App: React.FC = () => {
  return (
    <div>
      <Header />
      <main className="flex flex-col mt-16 w-full">
        <Hero />
        <SectionDivider />
        <Demo />
        <SectionDivider />
        <Subscription />
        {/* <SectionDivider />
        <Pricing />
        <SectionDivider />
        <Contact /> */}
      </main>
      <Footer />
    </div>
  );
};

export default App;
