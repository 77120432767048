import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLead } from '../api/useLead';

const Subscription: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const { save } = useLead();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      toast.error('Por favor, insira um email.');
      setError(true);
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error('Por favor, insira um email válido.');
      setError(true);
      return;
    }

    try {
      const response = await save(email);
      console.log(response);
      if (!response.ok) {
        if (response.status === 409) {
          toast.warn('Email já cadastrado. Em breve você receberá novidades.');
        } else {
          toast.error('Erro ao salvar inscrição.');
        }
        setError(true);
      } else {
        toast.success('Inscrição realizada com sucesso! Você será notificado sobre o lançamento e atualizações.');
        setEmail('');
        setError(false);  
      }
    } catch (err) {
      toast.error('Erro ao salvar inscrição.');
      setError(true);
    }
  };

  return (
    <section id="subscription" className="flex flex-col items-center justify-center my-8 w-full py-8 mb-20">
      <h2 className="text-4xl font-bold mb-4 text-center">Inscreva-se</h2>
      <p className="text-lg text-center max-w-2xl mb-8">
        Cadastre seu email para ser notificado sobre o lançamento da plataforma e receber outras atualizações. Fique por dentro de todas as novidades.
      </p>

      <form onSubmit={handleSubmit} className="w-full max-w-xl flex-col flex items-center md:flex-row">
          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`w-full md:flex-grow p-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 ${error ? 'focus:ring-red-500' : 'focus:ring-blue-600'} mb-5 md:mb-0`}
            placeholder="Digite seu email"
            style={{ width: '70%' }}
            required
          />
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 ml-4 rounded-lg text-lg font-semibold hover:bg-blue-500 transition duration-300"
            style={{ height: '42px' }}
          >
            Inscrever-se
          </button>
      </form>
      <ToastContainer 
        position="bottom-right" 
        autoClose={3000} 
        hideProgressBar 
        newestOnTop 
        closeOnClick 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
        theme="colored"
      />
    </section>
  );
};

export default Subscription;
