import React from 'react';

const SectionDivider: React.FC = () => {
  return (
    <div className="flex justify-center my-8 mt-16">
      <hr className="w-1/2 border-gray-300" />
    </div>
  );
};

export default SectionDivider;
