export const useLead = () => {
    const save = async (email: string) => {
        const body = {
            email
        };
        const BASE_URL = process.env.REACT_APP_API_URL;
        return await fetch(`${BASE_URL}/v1/lead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    };

    return { save };
}
