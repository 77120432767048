import React from 'react';
import { scrollToSection } from '../utils/scrollToSection';

const Demo: React.FC = () => {
  return (
    <section id="demo" className="flex flex-col md:flex-row items-center justify-center my-16 w-full px-4">
      <div className="w-full md:w-1/2 max-w-4xl mb-8 md:mb-0 md:mr-8">
        <iframe 
          className="w-full h-[300px] md:h-[400px] h-auto rounded-lg shadow-lg"
          src="https://www.youtube.com/embed/U0sEzPDTsBI"
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="w-full md:w-1/2 max-w-2xl flex flex-col items-center justify-center md:items-start text-center md:text-left">
        <h2 className="text-4xl font-extrabold mb-6 text-center md:text-left text-gray-900">Como Funciona</h2>
        <p className="text-lg text-gray-600 mb-8 leading-relaxed">
            Aproveite a oportunidade de transformar seus estudos com uma plataforma simples e intuitiva. 
            Com a nossa tecnologia, você será capaz de impulsionar seus ganhos nos estudos e aumentar 
            significativamente suas chances de aprovação em concursos e vestibulares por todo o país. 
            Não perca tempo, inscreva-se agora e dê o próximo passo para o seu sucesso.
        </p>
        <a
          onClick={() => scrollToSection('subscription')}
          className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-500 transition duration-300 shadow-lg cursor-pointer"
        >
          Inscreva-se
        </a>
      </div>
    </section>
  );
};

export default Demo;
