import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto text-center">
        <p>&copy; {new Date().getFullYear()} Prova Simples. Todos os direitos reservados.</p>
        <div className="flex justify-center space-x-3 mt-1">
          <a href="https://facebook.com" className="hover:underline"><i className="fab fa-facebook"></i></a>
          <a href="https://instagram.com" className="hover:underline"><i className="fab fa-instagram"></i></a>
          <a href="https://tiktok.com" className="hover:underline"><i className="fab fa-tiktok"></i></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
